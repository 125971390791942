import React, { useMemo } from "react"

import { useTranslation } from "react-i18next"

import { getOption } from "../../../../../../utils"
import { ROTATION_INTERVAL_OPTIONS } from "../../constants"

import {
  ImageFile,
  RotationInterval,
} from "../../../../../../redux/api/customContent/types"

import Button from "../../../../../../components/advanced/Button"
import Divider from "../../../../../../components/basic/Divider"
import { Input } from "../../../../../../components/basic/Input"
import { Select } from "../../../../../../components/basic/Select"
import { UploadFile } from "../../../../../../components/basic/UploadFile"

import DeselectSVG from "../../../../../../assets/images/icons/Deselect.svg"
import SelectSVG from "../../../../../../assets/images/icons/Select.svg"
import UploadSVG from "../../../../../../assets/images/icons/Upload.svg"

import "./ImageGalleryActions.sass"

type Props = {
  images: ImageFile[]
  selectedImageIds: string[]
  interval: RotationInterval
  onUploadFiles: (files: FileList | null) => Promise<void>
  onSelectAllFiles: () => void
  onDeselectAllFiles: () => void
  onChangeInterval: (key: string, value: number | string) => void
}

const ImageGalleryActions = ({
  images,
  selectedImageIds,
  interval,
  onSelectAllFiles,
  onDeselectAllFiles,
  onUploadFiles,
  onChangeInterval,
}: Props) => {
  const { t } = useTranslation()

  const isSelectAllDisabled = useMemo(
    () => images.length > 0 && selectedImageIds.length !== images.length,
    [images.length, selectedImageIds.length],
  )

  const isDeselectAllDisabled = useMemo(
    () => images.length > 0 && selectedImageIds.length === images.length,
    [images.length, selectedImageIds.length],
  )

  return (
    <div className="ImageGalleryActions">
      <UploadFile
        onChange={onUploadFiles}
        multiple
        buttonProps={{
          variant: "link",
          icon: <UploadSVG />,
        }}
      >
        {t(
          "desktop.settings.rooms.custom_content.forms.gallery.upload_image_button",
        )}
      </UploadFile>

      <Divider color="gray-3" />

      <div className="gallery-selection-options">
        <div className="Label">
          {t(
            "desktop.settings.rooms.custom_content.forms.gallery.selected_images",
            {
              count: selectedImageIds.length ?? 0,
            },
          )}
        </div>

        {!isDeselectAllDisabled && (
          <Button
            icon={<SelectSVG />}
            variant="link"
            onClick={onSelectAllFiles}
            disabled={images.length < 1 && isSelectAllDisabled}
          >
            {t(
              "desktop.settings.rooms.custom_content.forms.gallery.select_all_button",
            )}
          </Button>
        )}

        {!isSelectAllDisabled && (
          <Button
            icon={<DeselectSVG />}
            variant="link"
            onClick={onDeselectAllFiles}
            disabled={images.length < 1 && isDeselectAllDisabled}
          >
            {t(
              "desktop.settings.rooms.custom_content.forms.gallery.deselect_all_button",
            )}
          </Button>
        )}

        <div className="SubText">
          {t(
            "desktop.settings.rooms.custom_content.forms.gallery.selected_images_sub_text",
          )}
        </div>
      </div>

      <Divider color="gray-3" />

      <div className="gallery-image-rotation-interval">
        <div className="Label">
          {t(
            "desktop.settings.rooms.custom_content.forms.gallery.image_rotation_interval_label",
          )}
        </div>
        <div className="Fields">
          <Input
            type="number"
            min={1}
            max={999}
            value={interval.duration}
            onChange={(value) => onChangeInterval("duration", value)}
          />
          <Select
            options={ROTATION_INTERVAL_OPTIONS}
            value={getOption(ROTATION_INTERVAL_OPTIONS, interval.unit)}
            onChange={(option) => onChangeInterval("unit", option?.value ?? "")}
          />
        </div>
      </div>
    </div>
  )
}

export default ImageGalleryActions
