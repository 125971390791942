import React from "react"

import { Switch as RouteSwitch } from "react-router"
import { Redirect } from "react-router-dom"

import { ACCOUNT_PATHS } from "./constants"
import Email from "./Email"
import General from "./General"
import Notifications from "./Notifications"
import Password from "./Password"

import ProtectedRoute from "../../../components/ProtectedRoute"

const AccountSettings = () => {
  return (
    <RouteSwitch>
      <ProtectedRoute exact path={ACCOUNT_PATHS.general} component={General} />
      <ProtectedRoute exact path={ACCOUNT_PATHS.email} component={Email} />
      <ProtectedRoute
        exact
        path={ACCOUNT_PATHS.password}
        component={Password}
      />
      <ProtectedRoute
        exact
        path={ACCOUNT_PATHS.notifications}
        component={Notifications}
      />
      <Redirect to={ACCOUNT_PATHS.general} />
    </RouteSwitch>
  )
}

export default AccountSettings
