import React from "react"

import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import { useBookContext } from "../../../../contexts/Mobile/BookContext"
import { useAnalyticsScreenView } from "../../../../providers/Mobile/FirebaseAnalyticsProvider"
import NearbyDesks from "./NearbyDesks"

import { CorrectIconMapper } from "../../../../components/CorrectIconMapper"
import SafeViewArea from "../../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../../components/Mobile/TopNav"

import "./OccupiedDesk.sass"

const OccupiedDesk = () => {
  useAnalyticsScreenView("Book/Desks/OccupiedDesk")

  const { t } = useTranslation()
  const { goToHome } = useBookContext()
  const handleClose = () => goToHome()

  const location = useLocation<{ desk_id: string }>()
  const deskId = location.state?.desk_id

  return (
    <SafeViewArea className="OccupiedDesk">
      <div className="head">
        <TopNav onClose={handleClose} />
        <div className="icon">
          <div>
            <CorrectIconMapper iconType="desk" needsWrap={false} />
          </div>
        </div>
      </div>

      <div className="body">
        <div className="main-data">
          <div className="title">{t("mobile.general.occupied_desk")}</div>
          <div className="subtitle">
            {t("mobile.general.book_a_nearby_desk")}
          </div>

          <NearbyDesks deskId={deskId} />
        </div>
      </div>
    </SafeViewArea>
  )
}

export default OccupiedDesk
