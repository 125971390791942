import React from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { userTimeFormat } from "../../utils"
import { CorrectIconMapper } from "../CorrectIconMapper"

import { VisitorListResponse } from "../../redux/visitors/types"

import "./VisitorRow.sass"

type VisitorRowProps = {
  visitor: VisitorListResponse
}

export const VisitorRow = ({ visitor }: VisitorRowProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const start = dayjs(visitor.start ? visitor.start : visitor.checkin_at)
  const end = visitor.end ? dayjs(visitor.end) : undefined

  return (
    <div
      className="VisitorRow"
      onClick={() => {
        history.push("/home/visitor/" + visitor.id)
      }}
    >
      <div className="resource-info">
        <div className="visitor-title">{visitor.full_name}</div>
        <div className="aux-info">
          <span className="from">{start.format(userTimeFormat())}</span>
          <span className="dash">{end && " - "}</span>
          <span className="to">{end ? end.format(userTimeFormat()) : ""}</span>
          <span> &middot; </span>
          <span className="status">
            {visitor.checkout_at
              ? t("mobile.general.checked_out")
              : t("mobile.general.checked_in")}
          </span>
        </div>
      </div>
      <CorrectIconMapper iconType="visitor" />
    </div>
  )
}
