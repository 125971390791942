import { t } from "i18next"

import { OptionType, RecurringType } from "../../types/sharedTypes"

import timezones from "../../assets/Data/timezones.json"

/**
 * We're good guys so we're mapping some cities
 * if customers specifically request them.
 *
 * Example: https://futurevisions.atlassian.net/browse/JOAN-5394
 */
export const timezoneMapper = (timezone: string) => {
  switch (timezone) {
    case "Europe/Kiev":
      return "Europe/Kyiv"
    default:
      return timezone
  }
}

/**
 * This is calculated on page load and then simply reused.
 */
export const TIMEZONE_OPTIONS = timezones.map((timezone: string) => {
  return {
    value: timezone,
    label: timezoneMapper(timezone),
  }
})

export const RESERVATION_LENGTH_OPTIONS: OptionType<number>[] = [
  { value: 7, label: "1 week" },
  { value: 14, label: "2 weeks" },
  { value: 21, label: "3 weeks" },
  { value: 30, label: "30 days" },
  { value: 60, label: "60 days" },
  { value: 90, label: "90 days" },
]

export const DISABLED_CHECKIN_PERIOD = 300

export const CHECKIN_PERIOD_OPTIONS: OptionType<number>[] = [
  { value: DISABLED_CHECKIN_PERIOD, label: "Off" },
  { value: 900, label: "15 minutes" },
  { value: 1800, label: "30 minutes" },
  { value: 3600, label: "1 hour" },
  { value: 7200, label: "2 hours" },
  { value: 10800, label: "3 hours" },
]

export const HIDE_RESERVATION_WINDOW_LENGTH_OPTIONS: OptionType<number>[] = [
  { value: 0, label: "Off" },
  { value: 1, label: "1 day" },
  { value: 7, label: "7 days" },
  { value: 14, label: "14 days" },
  { value: 30, label: "30 days" },
  { value: 90, label: "90 days" },
]

export const getScheduleOptions = (
  day: string,
  showWeekends: boolean = false,
): OptionType<string | null>[] => {
  const options = [
    { label: t("general.repeat.once"), value: null },
    ...(showWeekends
      ? [
          {
            label: t("general.repeat.every_day"),
            value: RecurringType.EVERY_DAY,
          },
        ]
      : []),
    {
      label: t("general.repeat.every_weekday"),
      value: RecurringType.EVERY_WEEKDAY,
    },
    {
      label: t("general.repeat.every_day_of_week", { day }),
      value: RecurringType.EVERY_DAY_OF_WEEK,
    },
  ]
  return options
}

export const createScheduleOption = (value?: string, day?: string) => {
  switch (value) {
    case RecurringType.EVERY_DAY:
      return { label: "Every day", value: RecurringType.EVERY_DAY }
    case RecurringType.EVERY_WEEKDAY:
      return { label: "Every weekday", value: RecurringType.EVERY_WEEKDAY }
    case RecurringType.EVERY_DAY_OF_WEEK:
      return { label: `Every ${day}`, value: RecurringType.EVERY_DAY_OF_WEEK }
    default:
      return { label: "Once", value: null }
  }
}
