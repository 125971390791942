import { connectRouter, routerMiddleware } from "connected-react-router"
import { createBrowserHistory, History } from "history"
import { TypedUseSelectorHook, useSelector } from "react-redux"
import { AnyAction, combineReducers } from "redux"

import { Dispatcher } from "../api/dispatcher"
import { api } from "./api"
import { appReducer } from "./app/appSlice"
import { appointmentsReducer } from "./appointments/appointmentsSlice"
import { appParamsReducer } from "./appParams/appParamsSlice"
import { assetReservationReducer } from "./asset_reservation/assetReservationSlice"
import { assetScheduleReducer } from "./asset_schedule/assetScheduleSlice"
import { authReducer, clearAuth } from "./auth/authSlice"
import { autoScanRoomsReducer } from "./autoScanRooms/autoScanRoomsSlice"
import { bookAssetReducer } from "./book_asset/bookAssetSlice"
import { buildingsWithVMReducer } from "./buildings/buildingsWithVMSlice"
import { checkInsReducer } from "./check_ins/checkInsSlice"
import { consentReducer } from "./consent/consentSlice"
import { deskScheduleReducer } from "./desk_schedule/deskScheduleSlice"
import { devicesReducer } from "./devices/devicesSlice"
import { documentsReducer } from "./documents/documentsSlice"
import { eventsReducer } from "./events/eventsSlice"
import { experimentsReducer } from "./experiments/experimentsSlice"
import { featureFlagsReducer } from "./feature_flags/featureFlagsSlice"
import { filesReducer } from "./files/filesSlice"
import { inviteReducer } from "./invite/inviteSlice"
import { invitesReducer } from "./invites/invitesSlice"
import { apiErrorHandlingMiddleware, sentryMiddleware } from "./middlewares"
import { mobileReducers } from "./mobile/mobileReducers"
import { Feature, paymentReducer, setHasPaid } from "./payment/paymentSlice"
import { peopleScheduleReducer } from "./people_schedule/peopleScheduleSlice"
import { printerSettingsReducer } from "./printer_settings/printerSettingsSlice"
import { reservationsReducer } from "./reservations/reservationsSlice"
import { roomsReducer } from "./rooms/roomsSlice"
import { scimReducer } from "./scim/scimSlice"
import { screeningsReducer } from "./screenings/screeningsSlice"
import { screeningsReducerOld } from "./screenings/screeningsSliceOld"
import { settingsReducer } from "./settings/settingsSlice"
import { shareableReducer } from "./shareable/shareableSlice"
import { ssoProvidersReducer } from "./ssoProviders/ssoProvidersSlice"
import { suggestionsReducer } from "./suggestions/suggestionsSlice"
import { tabletReducer } from "./tablet/tabletSlice"
import { tabletSettingsReducer } from "./tablet_settings/tabletSettingsSlice"
import { userReducer } from "./user/userSlice"
import { userExperimentsReducer } from "./user_experiments/userExperimentsSlice"
import { usersReducer } from "./users/usersSlice"
import { visitorReducer } from "./visitor/visitorSlice"
import { visitorEmailSettingsReducer } from "./visitor_email_settings/visitorEmailSettingsSlice"
import { visitorLoginReducer } from "./visitor_login/visitorLoginSlice"
import { visitorRegistrationReducer } from "./visitor_registration/visitorRegistrationSlice"
import {
  visitorSettingsReducer,
  visitorSettingsUserReducer,
} from "./visitor_settings/visitorSettingsSlice"
import { visitorsReducer } from "./visitors/visitorsSlice"
import { configureStore } from "@reduxjs/toolkit"

export function logger(state = null, action: AnyAction) {
  /**
   * the redux has issues with serializing date objects.
   * we are changing date object to ISO string date for file actions
   * for all other actions we should pass ISO string date instead of date or dayjs object
   */
  if (typeof action.type === "string" && action.type.startsWith("api/")) {
    const { meta } = action
    return {
      ...action,
      meta: {
        ...meta,
        baseQueryMeta: {
          ...(meta?.baseQueryMeta ?? {}),
          request: meta?.baseQueryMeta?.request?.url ?? "",
          response: meta?.baseQueryMeta?.response?.url ?? "",
        },
      },
    }
  }
  if (
    typeof action.type === "string" &&
    action.type.includes("uploadSignature")
  ) {
    const { meta } = action
    return {
      ...action,
      meta: {
        ...meta,
        arg: {
          ...meta.arg,
          file: {
            ...meta.arg.file,
            lastModifiedDate: meta?.arg?.file.lastModifiedDate?.toISOString(),
          },
        },
      },
    }
  }

  if (
    typeof action.type === "string" &&
    (action.type.includes("files") || action.type.includes("importCSV"))
  ) {
    const { meta } = action
    return {
      ...action,
      meta: {
        ...meta,
        arg: {
          ...meta.arg,
          lastModifiedDate: meta?.arg?.lastModifiedDate?.toISOString(),
        },
      },
    }
  }
  return action
}

const createRootReducer = (history: History) => {
  return combineReducers({
    [api.reducerPath]: api.reducer,
    app: appReducer,
    appointments: appointmentsReducer,
    appParams: appParamsReducer,
    assetReservation: assetReservationReducer,
    assetSchedule: assetScheduleReducer,
    auth: authReducer,
    autoScanRooms: autoScanRoomsReducer,
    bookAsset: bookAssetReducer,
    buildingsWithVM: buildingsWithVMReducer,
    consent: consentReducer,
    deskSchedule: deskScheduleReducer,
    devices: devicesReducer,
    documents: documentsReducer,
    events: eventsReducer,
    experiments: experimentsReducer,
    featureFlags: featureFlagsReducer,
    files: filesReducer,
    invite: inviteReducer,
    invites: invitesReducer,
    lastAction: logger,
    mobile: mobileReducers,
    payment: paymentReducer,
    peopleSchedule: peopleScheduleReducer,
    printerSettings: printerSettingsReducer,
    reservations: reservationsReducer,
    rooms: roomsReducer,
    router: connectRouter(history),
    scim: scimReducer,
    screenings: screeningsReducer,
    screeningsOld: screeningsReducerOld,
    settings: settingsReducer,
    shareable: shareableReducer,
    ssoProviders: ssoProvidersReducer,
    suggestions: suggestionsReducer,
    tablet: tabletReducer,
    tabletSettings: tabletSettingsReducer,
    user_experiments: userExperimentsReducer,
    user: userReducer,
    users: usersReducer,
    visitor_login: visitorLoginReducer,
    visitor: visitorReducer,
    visitors: visitorsReducer,
    visitorEmailSettings: visitorEmailSettingsReducer,
    visitorRegistration: visitorRegistrationReducer,
    visitorSettings: visitorSettingsReducer,
    visitorSettingsUser: visitorSettingsUserReducer,
    checkIns: checkInsReducer,
  })
}

export const historyObject = createBrowserHistory()

const rootReducer = createRootReducer(historyObject)

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(routerMiddleware(historyObject))
      .concat(api.middleware)
      .concat(apiErrorHandlingMiddleware)
      .concat(sentryMiddleware)
  },
  preloadedState: {},
})

/**
 * This dispatcher was added to the store to allow the fetchExtended to dispatch events that are handled by the store.
 * This is a workaround to avoid circular dependencies.
 */
const dispatcher = Dispatcher.getInstance()

dispatcher.on("clearAuth", () => {
  store.dispatch(clearAuth())
})

dispatcher.on("setHasPaid", (feature: Feature) => {
  store.dispatch(setHasPaid(feature))
})

export type RootState = ReturnType<typeof store.getState>
export type ReducerKeys = keyof ReturnType<typeof rootReducer>
export type ReducerValues = RootState[keyof RootState]
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type AppDispatch = typeof store.dispatch

type FlattenKeys<T, Prefix extends string = ""> = {
  [K in keyof T]: K extends "mobile" | "billing" //add nested reducers keys
    ? FlattenKeys<T[K], `${Prefix}${string & K}.`>
    : `${Prefix}${string & K}`
}[keyof T]

export type ExtendedReducerKeys = FlattenKeys<RootState>
