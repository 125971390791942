import React, { useMemo, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { ParseKeys } from "i18next"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import {
  FETCH_FOR_COUNT,
  USER_DATE_WITH_TIME_FORMAT,
} from "../../../../constants"
import { useToast } from "../../../../hooks/useToast"
import { OptionType } from "../../../../types/sharedTypes"
import {
  CUSTOM_CONTENT_PATHS,
  DAYS_OF_WEEK_OPTIONS,
  DAYS_REG_EXP,
  REPEATS_WEEKLY_SUFFIX,
  SCHEDULE_REPEATS_OPTIONS,
  SCHEDULE_REPEATS_TYPES,
} from "./constants"
import { parseDaysOfWeek } from "./utils"
import { useModals } from "@mattjennings/react-modal-stack"

import {
  useDestroyCustomContentMutation,
  useFetchCustomContentListQuery,
} from "../../../../redux/api/customContent"
import {
  CustomContentResponse,
  Schedule,
} from "../../../../redux/api/customContent/types"

import Button from "../../../../components/advanced/Button"
import { ConfirmationModal } from "../../../../components/advanced/ConfirmationModal"
import MultiActionButton from "../../../../components/basic/MultiActionButton"
import Table, { Column } from "../../../../components/basic/Table"
import WarningCard from "../../../../components/basic/WarningCard"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import DeviceFilter from "../../../../components/Filter/DeviceFilter"
import Filters from "../../../../components/Filter/Filters"
import FilterSpace from "../../../../components/Filter/FilterSpace"
import StatusContentFilter from "../../../../components/Filter/StatusContentFilter"
import { FilterSpecialValues } from "../../../../components/Filter/types"
import Intro from "../../../../components/Intro"
import NoDataFound from "../../../../components/NoDataFound"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import ClockSVG from "../../../../assets/images/icons/Clock.svg"
import InfoSVG from "../../../../assets/images/icons/InfoOutlined.svg"

import "./styles.sass"

enum CustomContentBulkAction {
  DELETE = "DELETE",
}

const NoCustomContentFound = () => {
  const { t } = useTranslation()

  return (
    <NoDataFound>
      <>
        <InfoSVG />
        <p>
          {t("desktop.settings.rooms.custom_content.no_custom_content.title")}
        </p>
      </>
    </NoDataFound>
  )
}

const CustomContent = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { openModal, closeModal } = useModals()
  const { infoToast } = useToast()

  const [deviceFilter, setDeviceFilter] = useState<string>(
    FilterSpecialValues.ALL,
  )

  const [statusFilter, setStatusFilter] = useState<string>(
    FilterSpecialValues.ALL,
  )

  const { data: { results: customContentEntries = [] } = {}, isFetching } =
    useFetchCustomContentListQuery({
      devices: deviceFilter,
      status: statusFilter,
    })
  const { data: { count } = {} } =
    useFetchCustomContentListQuery(FETCH_FOR_COUNT)

  const [destroyCustomContent] = useDestroyCustomContentMutation()

  const [selectedRows, setSelectedRows] = useState<CustomContentResponse[]>([])

  const selectedIdsRows = useMemo(
    () => selectedRows.map((r) => r.id),
    [selectedRows],
  )

  const handleSelectRow = (r: CustomContentResponse[]) => setSelectedRows(r)

  const handleBulkAction = async (action: CustomContentBulkAction) => {
    switch (action) {
      case CustomContentBulkAction.DELETE:
        destroyCustomContent(selectedIdsRows)

        infoToast(
          t(
            "desktop.settings.rooms.custom_content.forms.general.toasts.custom_content_deleted",
            {
              count: selectedIdsRows.length,
            },
          ),
        )
        break
    }
  }

  const handleBulkActionConfirmation = (action: CustomContentBulkAction) => {
    if (!selectedIdsRows.length) {
      infoToast(
        t(
          "desktop.settings.rooms.custom_content.forms.general.toasts.no_custom_content_selected",
        ),
      )
      return
    }

    openModal(ConfirmationModal, {
      onConfirm: async () => {
        await handleBulkAction(action)
        closeModal()
      },
    })
  }

  const bulkActionOptions = useMemo<OptionType<CustomContentBulkAction>[]>(
    () =>
      Object.values(CustomContentBulkAction).map((action) => ({
        label: t(
          `desktop.settings.rooms.custom_content.actions.${action.toLocaleLowerCase()}` as ParseKeys,
        ),
        value: action,
      })),
    [t],
  )

  const handleRowClick = (r: CustomContentResponse) => {
    history.push(CUSTOM_CONTENT_PATHS.edit.root(r.id.toString()))
  }

  const tableColumns = useMemo<Column<CustomContentResponse>[]>(() => {
    return [
      {
        field: "name",
        label: t("desktop.settings.rooms.custom_content.table.name"),
        renderCell: ({ name, devices, status }) => {
          return (
            <>
              <div className={classNames("name", { active: !!status })}>
                <strong>{name || t("general.untitled")}</strong>
              </div>

              <div className="attached-devices">
                <Trans
                  i18nKey={
                    "desktop.settings.rooms.custom_content.show_on_devices"
                  }
                  values={{
                    number: `<strong>${devices.length}</strong>`,
                  }}
                />
              </div>
            </>
          )
        },
      },
      {
        field: "schedule",
        label: "",
        renderCell: ({ schedule }) => {
          const getScheduleRepeatLabel = (schedule: Schedule | null) => {
            const { REPEATS_DAILY } = SCHEDULE_REPEATS_TYPES

            if (!schedule || !schedule?.repeat)
              return SCHEDULE_REPEATS_OPTIONS[0].label

            if (schedule.repeat === REPEATS_DAILY)
              return t(
                "desktop.settings.rooms.custom_content.table.repeats_each_day",
              )

            if (schedule.repeat.includes(REPEATS_WEEKLY_SUFFIX)) {
              const dayValues = parseDaysOfWeek(
                schedule.repeat ?? "",
                DAYS_REG_EXP,
              )

              const days = DAYS_OF_WEEK_OPTIONS.filter((o) =>
                dayValues.includes(o.value),
              ).map(({ label }) => label)

              const daysString = days.join(", ") + "."

              return (
                <Trans
                  i18nKey="desktop.settings.rooms.custom_content.table.repeats_each_days"
                  values={{ days: `<strong>${daysString}</strong>` }}
                />
              )
            }
          }

          return (
            <div className="schedule-info">
              <div className="schedule-range-datetime">
                <ClockSVG />

                {!schedule ? (
                  t(
                    "desktop.settings.rooms.custom_content.forms.general.all_day_label",
                  )
                ) : (
                  <strong>
                    {dayjs(schedule.start)
                      .utc()
                      .format(USER_DATE_WITH_TIME_FORMAT)}
                    {" - "}
                    {dayjs(schedule.end)
                      .utc()
                      .format(USER_DATE_WITH_TIME_FORMAT)}
                  </strong>
                )}
              </div>
              <div className="schedule-type">
                {getScheduleRepeatLabel(schedule)}
              </div>
            </div>
          )
        },
      },
    ]
  }, [t])

  return (
    <View className="CustomContent">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.rooms.title"),
          t("desktop.settings.rooms.custom_content.title"),
        ]}
      />

      <Intro>
        <div className="description">
          {t("desktop.settings.rooms.custom_content.intro")}
        </div>

        <div className="buttons">
          <Button to={CUSTOM_CONTENT_PATHS.add.root} isSmall>
            {t("desktop.settings.rooms.custom_content.new_content_button")}
          </Button>
        </div>
      </Intro>

      <Space size={0.75} />

      <Filters>
        <DeviceFilter value={deviceFilter} onChange={setDeviceFilter} showAll />

        <StatusContentFilter value={statusFilter} onChange={setStatusFilter} />

        <FilterSpace />

        <MultiActionButton
          options={bulkActionOptions}
          onAction={handleBulkActionConfirmation}
          label={`${t("desktop.settings.rooms.custom_content.selected_button")} (${
            selectedIdsRows.length
          })`}
          isSmall
        />
      </Filters>

      <Space size={0.75} />

      <Table
        isSelectable
        loading={isFetching}
        rows={customContentEntries}
        columns={tableColumns}
        onSelectedRows={handleSelectRow}
        emptyTableCell={<NoCustomContentFound />}
        onRowClick={handleRowClick}
        footerInfo={
          count === 0 && (
            <NoDataFound warning className="OnboardingTableInfo">
              <div className="CustomContentTableNoViewBottom">
                <Trans i18nKey="desktop.settings.rooms.custom_content.no_custom_content.description">
                  <Button
                    className="CustomContentTableNoViewBottomButton"
                    variant="link"
                    to={CUSTOM_CONTENT_PATHS.add.root}
                  >
                    add custom content
                  </Button>
                </Trans>
              </div>
            </NoDataFound>
          )
        }
      />
    </View>
  )
}

export default CustomContent
