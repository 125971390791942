import React from "react"

import {
  Redirect,
  Switch as RouteSwitch,
  useRouteMatch,
} from "react-router-dom"

import { PERMISSIONS } from "../../../constants"
import { ROOMS_PATHS } from "./constants"
import CustomContent from "./CustomContent"
import { CUSTOM_CONTENT_PATHS } from "./CustomContent/constants"
import CustomContentType from "./CustomContent/ContentTypes"
import EditGalleryForm from "./CustomContent/ContentTypes/EditGalleryForm"
import EditTextForm from "./CustomContent/ContentTypes/EditTextForm"
import EditWebpageForm from "./CustomContent/ContentTypes/EditWebpageForm"
import SelectContentTypes from "./CustomContent/ContentTypes/SelectContentTypes"
import { CustomContextProvider } from "./CustomContent/CustomContentContext"
import CustomContentDevices from "./CustomContent/Devices"
import EditCustomContent from "./CustomContent/EditCustomContent"
import Devices from "./Devices"
import DeviceDetail from "./Devices/DeviceDetail"
import PairRoomDevice from "./Devices/PairDevice"
import DeviceSettings from "./DeviceSettings"
import RoomList from "./Rooms"
import EditRoom from "./Rooms/EditRoom"
import RoomsOverview from "./RoomsOverviewPage"

import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { isPortalAdmin } from "../../../redux/user/utils"

import ProtectedRoute from "../../../components/ProtectedRoute"

const Rooms = () => {
  const changeDeskPermission = PERMISSIONS.desks.canChangeDesk
  const { entry: currentUser } = useAppSelector(selectUser)
  const isAdmin = isPortalAdmin(currentUser)

  return (
    <RouteSwitch>
      {isAdmin && (
        <ProtectedRoute
          exact
          path={ROOMS_PATHS.overview}
          accessPermission={changeDeskPermission}
          component={RoomsOverview}
        />
      )}
      <ProtectedRoute
        exact
        path={ROOMS_PATHS.rooms}
        // accessPermission="" TODO: Add proper access permissions
        component={RoomList}
      />
      <ProtectedRoute
        exact
        path={ROOMS_PATHS.addRoom}
        // accessPermission=""
        component={EditRoom}
      />
      <ProtectedRoute
        exact
        path={`${ROOMS_PATHS.rooms}/:key`}
        // accessPermission="" TODO: Add proper access permissions
        component={EditRoom}
      />
      <ProtectedRoute
        exact
        path={ROOMS_PATHS.devices}
        // accessPermission="" TODO: Add proper access permissions
        component={Devices}
      />
      <ProtectedRoute
        exact
        path={ROOMS_PATHS.pair}
        // accessPermission="" TODO: Add proper access permissions
        component={PairRoomDevice}
      />
      <ProtectedRoute
        exact
        path={`${ROOMS_PATHS.devices}/:device_id`}
        // accessPermission="" TODO: Add proper access permissions
        component={DeviceDetail}
      />

      <ProtectedRoute
        exact
        path={ROOMS_PATHS.deviceSettings}
        // accessPermission="" TODO: Add proper access permissions
        component={DeviceSettings}
      />

      <ProtectedRoute
        exact
        path={ROOMS_PATHS.customContent}
        // accessPermission="" TODO: Add proper access permissions
        component={CustomContent}
      />

      <ProtectedRoute
        exact
        path={ROOMS_PATHS.root}
        // accessPermission="" TODO: Add proper access permissions
        render={() =>
          isAdmin ? (
            <Redirect to={ROOMS_PATHS.overview} />
          ) : (
            <Redirect to={ROOMS_PATHS.rooms} />
          )
        }
      />

      <CustomContextProvider>
        <RouteSwitch>
          {/*ADD*/}
          <ProtectedRoute
            exact
            path={CUSTOM_CONTENT_PATHS.add.root}
            // accessPermission="" TODO: Add proper access permissions
            component={EditCustomContent}
          />

          <ProtectedRoute
            exact
            path={CUSTOM_CONTENT_PATHS.add.contentTypes}
            // accessPermission="" TODO: Add proper access permissions
            component={() => (
              <CustomContentType>
                <SelectContentTypes />
              </CustomContentType>
            )}
          />

          <ProtectedRoute
            exact
            path={CUSTOM_CONTENT_PATHS.add.gallery}
            // accessPermission="" TODO: Add proper access permissions
            component={() => (
              <CustomContentType>
                <EditGalleryForm />
              </CustomContentType>
            )}
          />

          <ProtectedRoute
            exact
            path={CUSTOM_CONTENT_PATHS.add.text}
            // accessPermission="" TODO: Add proper access permissions
            component={() => (
              <CustomContentType>
                <EditTextForm />
              </CustomContentType>
            )}
          />

          <ProtectedRoute
            exact
            path={CUSTOM_CONTENT_PATHS.add.webpage}
            // accessPermission="" TODO: Add proper access permissions
            component={() => (
              <CustomContentType>
                <EditWebpageForm />
              </CustomContentType>
            )}
          />

          <ProtectedRoute
            exact
            path={CUSTOM_CONTENT_PATHS.add.devices}
            // accessPermission="" TODO: Add proper access permissions
            component={CustomContentDevices}
          />

          <ProtectedRoute
            exact
            path={CUSTOM_CONTENT_PATHS.edit.root(":content_id")}
            // accessPermission="" TODO: Add proper access permissions
            component={EditCustomContent}
          />

          <ProtectedRoute
            exact
            path={CUSTOM_CONTENT_PATHS.edit.contentTypes(":content_id")}
            // accessPermission="" TODO: Add proper access permissions
            component={() => (
              <CustomContentType>
                <SelectContentTypes />
              </CustomContentType>
            )}
          />

          <ProtectedRoute
            exact
            path={CUSTOM_CONTENT_PATHS.edit.gallery(":content_id")}
            // accessPermission="" TODO: Add proper access permissions
            component={() => (
              <CustomContentType>
                <EditGalleryForm />
              </CustomContentType>
            )}
          />

          <ProtectedRoute
            exact
            path={CUSTOM_CONTENT_PATHS.edit.text(":content_id")}
            // accessPermission="" TODO: Add proper access permissions
            component={() => (
              <CustomContentType>
                <EditTextForm />
              </CustomContentType>
            )}
          />

          <ProtectedRoute
            exact
            path={CUSTOM_CONTENT_PATHS.edit.webpage(":content_id")}
            // accessPermission="" TODO: Add proper access permissions
            component={() => (
              <CustomContentType>
                <EditWebpageForm />
              </CustomContentType>
            )}
          />

          <ProtectedRoute
            exact
            path={CUSTOM_CONTENT_PATHS.edit.devices(":content_id")}
            // accessPermission="" TODO: Add proper access permissions
            component={CustomContentDevices}
          />
        </RouteSwitch>
      </CustomContextProvider>
    </RouteSwitch>
  )
}

export default Rooms
