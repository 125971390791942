import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react"

import { NFCLaunch } from "capacitor-nfc-launch"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

import { useRefetch } from "../../../hooks/mobile/useRefetch"
import { DESK_PATHS } from "../../../screens/Mobile/Book/Desks/constants"
import { checkinUrlRegex } from "../../../screens/Mobile/QRScanner"
import { NFCMessageResponse } from "../../../types/sharedTypes"
import {
  openInAppBrowser,
  removePluginListeners,
  sanitizeUrl,
} from "../../../utils"
import { App as CapApp, URLOpenListenerEvent } from "@capacitor/app"

import {
  useFetchDeskReservationsQuery,
  useFetchMyDeskReservationsQuery,
} from "../../../redux/api/deskReservations"
import { useAppSelector } from "../../../redux/reducers"
import { selectSettingsEffective } from "../../../redux/settings/selectors"
import { selectUser } from "../../../redux/user/selectors"

export const DeepLinkContext = React.createContext({})

const DeepLinkProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { t } = useTranslation()
  let history = useHistory()

  const isFrontdesk = (path: string) => {
    return (
      path.startsWith("/visitor-login") ||
      (path.startsWith("/health-screening") && !path.includes("employee"))
    )
  }

  const isVisitorPath = (path: string) => {
    return path.startsWith("/visitors")
  }

  const [deskId, setDeskId] = useState<string | null>(null)
  const date = dayjs()
  const startOfDay = date.startOf("day").toISOString()
  const endOfDay = date.endOf("day").toISOString()
  const user = useAppSelector(selectUser)
  const {
    desk_one_reservation_per_user: isOnlyOneReservationPerUser,
    desk_check_in_enabled: isDeskCheckInEnabled,
  } = useAppSelector(selectSettingsEffective).entry ?? {}

  // Fetch all desk reservations for the day
  const {
    data: deskReservations,
    refetch: refetchDeskReservations,
    isSuccess: isDeskReservationsLoaded,
  } = useFetchDeskReservationsQuery({
    start: startOfDay,
    end: endOfDay,
  })

  // Fetch user's desk reservations for the day
  const {
    data: myDeskReservations,
    refetch: refetchMyDeskReservations,
    isSuccess: isMyDeskReservationsLoaded,
  } = useFetchMyDeskReservationsQuery({
    start: startOfDay,
    end: endOfDay,
  })

  // Refetch queries automatically when the app resumes activity
  useRefetch([refetchDeskReservations, refetchMyDeskReservations])

  const handleDeskBookingProcess = useCallback(
    (deskId: string) => {
      const reservationId = myDeskReservations?.results?.find(
        (reservation) => reservation.desk.id === deskId,
      )?.id

      const isAnotherDeskAlreadyReservedByUser =
        myDeskReservations?.results?.some(
          (reservation) => reservation.desk.id !== deskId,
        ) || false

      const isDeskAlreadyReservedBySomeoneElse =
        deskReservations?.results?.some(
          (reservation) =>
            reservation.desk.id === deskId &&
            reservation.user.email !== user.entry.email,
        ) || false

      const isDeskAlreadyReservedByUser =
        myDeskReservations?.results?.some(
          (reservation) => reservation.desk.id === deskId,
        ) || false

      const isDeskAlreadyCheckedInByUser =
        myDeskReservations?.results?.some(
          (reservation) =>
            reservation.desk.id === deskId && reservation.checked_in !== null,
        ) || false

      // Navigate to the correct screen based on the conditions
      if (
        isDeskCheckInEnabled &&
        isDeskAlreadyReservedByUser &&
        !isDeskAlreadyCheckedInByUser
      ) {
        // If the user has already reserved this desk but hasn't checked in yet and desk check-in is enabled, redirect to the check-in screen
        history.push({
          pathname: DESK_PATHS.checkin,
          state: { desk_id: deskId }, // Pass the deskId to the check-in screen
        })
      } else if (
        isOnlyOneReservationPerUser &&
        (isDeskAlreadyReservedByUser || isDeskAlreadyCheckedInByUser)
      ) {
        // If the user has already reserved or checked in this desk and one reservation per user is enabled, redirect to the reservation details screen
        history.push({
          pathname: DESK_PATHS.reservation.replace(
            ":reservationId",
            reservationId ?? "",
          ),
        })
      } else if (
        isOnlyOneReservationPerUser &&
        isAnotherDeskAlreadyReservedByUser
      ) {
        // If the user has already reserved or checked in another desk and one reservation per user is disabled, redirect to the wrong screen
        history.push(DESK_PATHS.wrong)
      } else if (isDeskAlreadyReservedBySomeoneElse) {
        // If the desk is already reserved by someone else, redirect to the occupied screen
        history.push({
          pathname: DESK_PATHS.occupied,
          state: { desk_id: deskId }, // Pass the deskId to the occupied screen
        })
      } else {
        // If none of the above, redirect to the confirm screen
        history.push({
          pathname: DESK_PATHS.confirm,
          state: { desk_id: deskId }, // Pass the deskId to the confirm screen
        })
      }
    },
    [
      deskReservations,
      myDeskReservations,
      history,
      isDeskCheckInEnabled,
      isOnlyOneReservationPerUser,
      user,
    ],
  )

  useEffect(() => {
    if (deskId) {
      const fetchAndProcess = async () => {
        // Refetch queries to have the latest data
        await Promise.all([
          refetchDeskReservations(),
          refetchMyDeskReservations(),
        ])

        // Process the desk booking process
        if (isDeskReservationsLoaded && isMyDeskReservationsLoaded) {
          handleDeskBookingProcess(deskId)
          setDeskId(null)
        }
      }
      fetchAndProcess()
    }
  }, [
    deskId,
    refetchDeskReservations,
    refetchMyDeskReservations,
    isDeskReservationsLoaded,
    isMyDeskReservationsLoaded,
    handleDeskBookingProcess,
  ])

  const deepLinkHandlingLogic = async (url: URL) => {
    if (url.pathname) {
      if (isFrontdesk(url.pathname)) {
        // Frontdesk paths
        window.location.href =
          window.location.origin + url.pathname + url.search
      } else if (isVisitorPath(url.pathname)) {
        // Visitor paths
        await openInAppBrowser(url.toString())
      } else if (url.pathname.match(checkinUrlRegex)) {
        // Desk booking paths
        const match = checkinUrlRegex.exec(url.pathname)
        if (match && match[1]) {
          setDeskId(match[1]) // Set the deskId state to the deskId from the URL
        }
      } else {
        // Other paths
        history.push(url.pathname)
      }
    }
  }

  useEffect(() => {
    const capListener = CapApp.addListener(
      "appUrlOpen",
      async (data: URLOpenListenerEvent) => {
        const url = new URL(data.url)
        deepLinkHandlingLogic(url)
      },
    )

    /**
     * NFC on Android requires special handling, since Capacitor doesn't know
     * how to read any data from intents triggered by NDEF_DISCOVERED, just by
     * VIEW which is handled in the listener above.
     *
     * NFC tag can be corrupted hence the error handling.
     */
    const nfcListener = NFCLaunch.addListener(
      "message",
      (data?: NFCMessageResponse) => {
        if (data && data.message) {
          const message = sanitizeUrl(data.message)

          try {
            const url = new URL(message)
            deepLinkHandlingLogic(url)
          } catch (e) {
            toast.error(t("mobile.nfc_provider.error"))
          }
        }
      },
    )

    return () => {
      removePluginListeners(capListener)
      removePluginListeners(nfcListener)
    }
  }, [history])

  return (
    <DeepLinkContext.Provider value={{}}>{children}</DeepLinkContext.Provider>
  )
}

export default DeepLinkProvider
