import React, { useEffect } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { NavLink, useParams } from "react-router-dom"

import { analyticsEvent, SupportedEvents } from "../../../analytics"
import { shortUserTimeFormat } from "../../../utils"

import { fetchInvite } from "../../../redux/invite/inviteSlice"
import { selectInvite } from "../../../redux/invite/selectors"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import Loader from "../../../components/basic/Loader"
import { CorrectIconMapper } from "../../../components/CorrectIconMapper"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"

import BuildingSVG from "../../../assets/images/icons/Building.svg"
import CalendarSVG from "../../../assets/images/icons/Calendar.svg"
import ClockSVG from "../../../assets/images/icons/Clock.svg"
import MapsSVG from "../../../assets/images/icons/Maps.svg"

import "./InviteDetail.sass"

type ParamsType = {
  id: string
}

const InviteDetail = () => {
  const { t } = useTranslation()
  const { id } = useParams<ParamsType>()

  const { entry: invite, isLoading } = useAppSelector(selectInvite)

  const actions = useActions({
    fetchInvite: (id: string) => fetchInvite(id),
  })

  useEffect(() => {
    if (id && (!invite || invite.id !== id)) {
      actions.fetchInvite(id).then((response) => {
        if (fetchInvite.fulfilled.match(response)) {
          analyticsEvent(SupportedEvents.VISITOR_USER_OPEN_INVITE_CARD, {
            id: response?.payload.id,
            name: response?.payload.full_name,
            building_id: response?.payload.building?.id,
            visitor_email: response?.payload.email,
            host_email: response?.payload.host?.email,
          })
        }
      })
    }
  }, [actions, id, invite])

  return (
    <SafeViewArea className="InviteDetail">
      <div className="head">
        <TopNav />
        <div className="icon">
          <div>
            <CorrectIconMapper iconType="visitor" needsWrap={false} />
          </div>
        </div>
      </div>
      <div className="body">
        {invite && invite.id === id && !isLoading && (
          <>
            <div className="main-data">
              <div className="info">
                <div className="type">{t("mobile.general.visitor")}</div>
                <div className="status">{t("mobile.general.expected")}</div>
              </div>
              <div className="name">{invite.full_name}</div>
            </div>
            <div className="details">
              <div className="detail-row">
                <BuildingSVG />
                <div>{invite.building.name}</div>
              </div>
              {!!invite.desk && (
                <div className="detail-row">
                  <CorrectIconMapper iconType="desk" needsWrap={false} />
                  <div>{invite.desk.name}</div>
                </div>
              )}
              {!!invite.reservation_id && (
                <div className="detail-row">
                  <NavLink
                    to={"/home/reservation/" + invite.reservation_id + "/map"}
                  >
                    <MapsSVG />
                    <div>{t("mobile.home.show_on_map")}</div>
                  </NavLink>
                </div>
              )}
              <div className="detail-row">
                <CalendarSVG />
                <div>{dayjs(invite.start).format("dddd MMM D, YYYY")}</div>
              </div>
              <div className="detail-row">
                <ClockSVG />
                <div>
                  {dayjs(invite.start).format(shortUserTimeFormat())}
                  {" - "}
                  {dayjs(invite.end).format(shortUserTimeFormat())}
                </div>
              </div>
            </div>
          </>
        )}
        {isLoading && (
          <div className="loading">
            <Loader />
          </div>
        )}
      </div>
    </SafeViewArea>
  )
}

export default InviteDetail
