import React from "react"

import classNames from "classnames"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useToast } from "../../../../hooks/useToast"

import { MeResponse } from "../../../../redux/api/me/types"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"
import { useUpdateUserMutation } from "../../../../redux/api/users"
import { UserProfileRequest } from "../../../../redux/api/users/types"
import { isPortalAdmin } from "../../../../redux/user/utils"

import Checkbox from "../../../../components/basic/Checkbox"
import Field from "../../../../components/Field"
import { setErrors } from "../../../../components/Form/formUtils"
import PageForm from "../../../../components/Form/PageFormHook"
import Space from "../../../../components/Space"

type Props = {
  user?: MeResponse
}

type FormValues = {
  notify_room?: boolean
  notify_desk?: boolean
  notify_visitor?: boolean
  notify_system?: boolean
  notify_channel_email?: boolean
  notify_channel_slack?: boolean
  notify_channel_teams?: boolean
  notify_channel_sms?: boolean
}

const formMapping = {
  "profile.notify_room": "notify_room",
  "profile.notify_desk": "notify_desk",
  "profile.notify_visitor": "notify_visitor",
  "profile.notify_system": "notify_system",
  "profile.notify_channel_email": "notify_channel_email",
  "profile.notify_channel_slack": "notify_channel_slack",
  "profile.notify_channel_teams": "notify_channel_teams",
  "profile.notify_channel_sms": "notify_channel_sms",
} as const

const NotificationsForm = ({ user }: Props) => {
  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()

  const [saveUser] = useUpdateUserMutation()

  const isAdmin = isPortalAdmin(user ?? { groups: [] })

  const methods = useForm<FormValues>({
    defaultValues: {
      notify_room: !!user?.profile?.notify_room,
      notify_desk: !!user?.profile?.notify_desk,
      notify_visitor: !!user?.profile?.notify_visitor,

      notify_channel_email: !!user?.profile?.notify_channel_email,
      notify_channel_slack: !!user?.profile?.notify_channel_slack,
      notify_channel_teams: !!user?.profile?.notify_channel_teams,
      notify_channel_sms: !!user?.profile?.notify_channel_sms,
      ...(isAdmin && { notify_system: !!user?.profile?.notify_system }),
    },
  })

  const { control, setError } = methods

  const onUpdateClick = async (values: FormValues) => {
    const profile: UserProfileRequest = {
      notify_room: values.notify_room,
      notify_desk: values.notify_desk,
      notify_visitor: values.notify_visitor,
      notify_channel_email: values.notify_channel_email,
      notify_channel_slack: values.notify_channel_slack,
      notify_channel_teams: values.notify_channel_teams,
      notify_channel_sms: values.notify_channel_sms,
      ...(isAdmin && { notify_system: values.notify_system }),
    }

    const payload = {
      first_name: user?.first_name ?? "",
      last_name: user?.last_name ?? "",
      profile,
      email: user?.email ?? "",
    }

    const response = await saveUser(payload)

    if (response && isRejected(response)) {
      if (isApiResponseError(response.error)) {
        setErrors(response.error.formError, setError, errorToast, formMapping)
      }
      return
    }

    infoToast(
      t("desktop.settings.profile.notifications.form.update_success_toast"),
    )
  }

  const NotifyOptionsCn = classNames({
    NotifyOptions: isAdmin,
  })

  const FieldWidthCn = classNames({
    "field-width-50": isAdmin,
  })

  return (
    <FormProvider {...methods}>
      <PageForm
        className="NotificationsForm"
        updateMode
        onUpdate={onUpdateClick}
      >
        <div className="EventNotificationsHeader">
          {t("desktop.settings.profile.notifications.form.notifications_intro")}
        </div>

        <Space size={0.75} />

        <div className={NotifyOptionsCn}>
          <div className={FieldWidthCn}>
            <Field control={control} name="notify_desk">
              {(props) => (
                <Checkbox
                  {...props}
                  label={t(
                    "desktop.settings.profile.notifications.form.notify_desk",
                  )}
                  description={t(
                    "desktop.settings.profile.notifications.form.notify_desk_description",
                  )}
                  isSecondary
                />
              )}
            </Field>
          </div>
          <div className={FieldWidthCn}>
            <Field control={control} name="notify_visitor">
              {(props) => (
                <Checkbox
                  {...props}
                  label={t(
                    "desktop.settings.profile.notifications.form.notify_visitor",
                  )}
                  description={t(
                    "desktop.settings.profile.notifications.form.notify_visitor_description",
                  )}
                  isSecondary
                />
              )}
            </Field>
          </div>

          <div className={FieldWidthCn}>
            <Field control={control} name="notify_room">
              {(props) => (
                <Checkbox
                  {...props}
                  label={t(
                    "desktop.settings.profile.notifications.form.notify_room",
                  )}
                  description={t(
                    "desktop.settings.profile.notifications.form.notify_room_description",
                  )}
                  isSecondary
                />
              )}
            </Field>
          </div>

          {isAdmin && (
            <div className={FieldWidthCn}>
              <Field control={control} name="notify_system">
                {(props) => (
                  <Checkbox
                    {...props}
                    label={t(
                      "desktop.settings.profile.notifications.form.notify_system",
                    )}
                    description={t(
                      "desktop.settings.profile.notifications.form.notify_system_description",
                    )}
                    isSecondary
                  />
                )}
              </Field>
            </div>
          )}
        </div>

        <hr />

        <div className="NotifyChannelHeader">
          {t(
            "desktop.settings.profile.notifications.form.notify_channel_intro",
          )}
        </div>

        <Space size={0.75} />

        <Field control={control} name="notify_channel_email">
          {(props) => (
            <Checkbox
              {...props}
              label={t(
                "desktop.settings.profile.notifications.form.notify_channel_email",
              )}
              isSecondary
            />
          )}
        </Field>

        <Field control={control} name="notify_channel_sms">
          {(props) => (
            <Checkbox
              {...props}
              label={t(
                "desktop.settings.profile.notifications.form.notify_channel_sms",
              )}
              isSecondary
            />
          )}
        </Field>

        <Field control={control} name="notify_channel_slack">
          {(props) => (
            <Checkbox
              {...props}
              label={t(
                "desktop.settings.profile.notifications.form.notify_channel_slack",
              )}
              isSecondary
            />
          )}
        </Field>

        <Field control={control} name="notify_channel_teams">
          {(props) => (
            <Checkbox
              {...props}
              label={t(
                "desktop.settings.profile.notifications.form.notify_channel_teams",
              )}
              isSecondary
            />
          )}
        </Field>
      </PageForm>
    </FormProvider>
  )
}

export default NotificationsForm
