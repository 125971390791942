import dayjs from "dayjs"
import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import { DEFAULT_NS, WHITELABEL_NS } from "./constants"
import "dayjs/locale/de"
import "dayjs/locale/es"
import "dayjs/locale/fr"
import "dayjs/locale/nl"
import "dayjs/locale/pl"

import deWhitelabel from "./assets/i18n/de/de-whitelabel.json"
import de from "./assets/i18n/de/de.json"
import enWhitelabel from "./assets/i18n/en/en-whitelabel.json"
import en from "./assets/i18n/en/en.json"
import esWhitelabel from "./assets/i18n/es/es-whitelabel.json"
import es from "./assets/i18n/es/es.json"
import foWhitelabel from "./assets/i18n/fo/fo-whitelabel.json"
import fo from "./assets/i18n/fo/fo.json"
import frWhitelabel from "./assets/i18n/fr/fr-whitelabel.json"
import fr from "./assets/i18n/fr/fr.json"
import nlWhitelabel from "./assets/i18n/nl/nl-whitelabel.json"
import nl from "./assets/i18n/nl/nl.json"
import plWhitelabel from "./assets/i18n/pl/pl-whitelabel.json"
import pl from "./assets/i18n/pl/pl.json"
import slWhitelabel from "./assets/i18n/sl/sl-whitelabel.json"
import sl from "./assets/i18n/sl/sl.json"
import DutchSVG from "./assets/images/icons/Dutch.svg"
import EnglishSVG from "./assets/images/icons/English.svg"
import FaroeseSVG from "./assets/images/icons/Faroese.svg"
import FrenchSVG from "./assets/images/icons/French.svg"
import GermanSVG from "./assets/images/icons/German.svg"
import PolishSVG from "./assets/images/icons/Polish.svg"
import SlovenianSVG from "./assets/images/icons/Slovenian.svg"
import SpanishSVG from "./assets/images/icons/Spanish.svg"

// Translations
export const resources = {
  en: {
    [DEFAULT_NS]: en,
    [WHITELABEL_NS]: enWhitelabel,
  },
  es: {
    [DEFAULT_NS]: es,
    [WHITELABEL_NS]: esWhitelabel,
  },

  de: {
    [DEFAULT_NS]: de,
    [WHITELABEL_NS]: deWhitelabel,
  },
  nl: {
    [DEFAULT_NS]: nl,
    [WHITELABEL_NS]: nlWhitelabel,
  },
  fo: {
    [DEFAULT_NS]: fo,
    [WHITELABEL_NS]: foWhitelabel,
  },
  fr: {
    [DEFAULT_NS]: fr,
    [WHITELABEL_NS]: frWhitelabel,
  },
  pl: {
    [DEFAULT_NS]: pl,
    [WHITELABEL_NS]: plWhitelabel,
  },
  sl: {
    [DEFAULT_NS]: sl,
    [WHITELABEL_NS]: slWhitelabel,
  },
} as const

export const DEFAULT_LANGUAGE = "en"

export const AVAILABLE_LANGUAGES = Object.keys(resources)

// For visitor management
export const VM_LANGUAGES = AVAILABLE_LANGUAGES

// For web
export const WEB_LANGUAGES = AVAILABLE_LANGUAGES.filter(
  (lang) => lang !== "fo" && lang !== "sl",
)

export const ORGANIZATION_LANGUAGE_OPTIONS = [
  { label: "English", value: "en" },
  { label: "Deutsch", value: "de" },
  { label: "Español", value: "es" },
  { label: "Français", value: "fr" },
  { label: "Nederlands", value: "nl" },
  { label: "Polski", value: "pl" },
  { label: "Føroyskt", value: "fo" },
  { label: "Slovenščina", value: "sl" },
]

export const LANGUAGE_ICONS: Record<string, React.FC> = {
  es: SpanishSVG,
  en: EnglishSVG,
  de: GermanSVG,
  nl: DutchSVG,
  fr: FrenchSVG,
  pl: PolishSVG,
  fo: FaroeseSVG,
  sl: SlovenianSVG,
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["navigator"],
    },
  })

i18n.services.formatter?.add(
  "enHandleAn",
  (value: any, lng: string | undefined) => {
    if (!lng?.startsWith("en")) {
      return value
    }
    if (typeof value === "string") {
      const letters = ["a", "e", "i", "o", "u", "h"]
      if (letters.includes(value.toLocaleLowerCase()[0])) {
        return `an ${value}`
      }
      return `a ${value}`
    }
    return value
  },
)

let langShort = i18n.language
if (langShort.includes("-")) {
  langShort = langShort.split("-")[0]
}

dayjs.locale(langShort)

export default i18n
