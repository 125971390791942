const ORGANIZATION_ROOT = "/settings/organization"
const ORGANIZATION_BRANDING = `${ORGANIZATION_ROOT}/branding`
const ORGANIZATION_COMPANY_DETAILS = `${ORGANIZATION_ROOT}/company-details`
const ORGANIZATION_COMPANY_DETAILS_DELETE = `${ORGANIZATION_COMPANY_DETAILS}/delete`
const ORGANIZATION_COMPANY_DETAILS_SURVEY = `${ORGANIZATION_COMPANY_DETAILS_DELETE}/survey`

export const DELETE_COMPANY_SURVEY_URL =
  "https://docs.google.com/forms/d/e/1FAIpQLSdftRY3FIUaFGWC7cgl3zDjg2oOdohZgQVZfA6gFhnkwQOWrw/viewform"

export const ORGANIZATION_PATHS = {
  root: ORGANIZATION_ROOT,
  branding: ORGANIZATION_BRANDING,
  company_details: ORGANIZATION_COMPANY_DETAILS,
  company_details_delete: ORGANIZATION_COMPANY_DETAILS_DELETE,
  company_details_survey: ORGANIZATION_COMPANY_DETAILS_SURVEY,
} as const
