import React from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { userTimeFormat } from "../../utils"
import { CorrectIconMapper } from "../CorrectIconMapper"

import { InviteListResponse } from "../../redux/invites/types"

import "./InviteRow.sass"

type InviteRowProps = {
  invite: InviteListResponse
}

export const InviteRow = ({ invite }: InviteRowProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const start = dayjs(invite.start).format(userTimeFormat())
  const end = dayjs(invite.end).format(userTimeFormat())

  return (
    <div
      className="InviteRow"
      onClick={() => {
        history.push("/home/invite/" + invite.id)
      }}
    >
      <div className="resource-info">
        <div className="invite-title">{invite.full_name}</div>
        <div className="aux-info">
          <span className="from">{start}</span>
          <span className="dash">{" - "}</span>
          <span className="to">{end}</span>
          <span> &middot; </span>
          <span className="status">{t("mobile.general.expected")}</span>
        </div>
      </div>
      <CorrectIconMapper iconType="visitor" />
    </div>
  )
}
